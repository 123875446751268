import { useNavigate } from "react-router-dom";
import { storage } from "../utils/storage";
import { useGetAccessToken } from "../services/user.service";
import { UseMutationResult } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const isUserStatusHasPermission = (statusId: any) => {
    if (statusId !== null && statusId !== undefined) {
        if (storage.getUserStatusId() !== statusId.toString()) {
            return true;
        }
        return false;
    };
};

export const isUserExchangeStatusHasPermission = (statusId: any) => {
    if (statusId !== null && statusId !== undefined) {
        if (storage.getUserExchangeStatusId() !== statusId.toString()) {
            return true;
        }
        return false;
    };
};

export const nth = (d: number) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
};

const getOrdinalSuffix = (day: number) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
};

export const formatDateWithOrdinal = (date: number | Date) => {
    const d = new Date(date);
    const day = d.getDate();
    const year = d.getFullYear();
    const month = d.toLocaleString('en-US', { month: 'short' });
    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
};

export const timeAgo = (date: number) => {
    const currentDate: any = new Date();
    const pastDate: any = new Date(date);

    const diffInSeconds = Math.ceil((currentDate - pastDate) / 1000);
    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;

    if (diffInSeconds < minute) {
        return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < hour) {
        return `${Math.ceil(diffInSeconds / minute)}m ago`;
    } else if (diffInSeconds < day) {
        return `${Math.ceil(diffInSeconds / hour)}h ago`;
    } else {
        return `${Math.ceil(diffInSeconds / day)}d ago`;
    }
};

export const getDateOnly = (date: any) => {
    try {
        return new Date(date.toISOString().split('T')[0]);
    } catch (error) {
        return new Date(date);
    }
};

export const formatDateTime = (value: number | Date, format: string) => {
    let date = new Date(value);
    // Define format patterns
    const patterns = {
        "DD": () => String(date.getDate()).padStart(2, '0'), // Day of the month (01-31)
        "DS": () => String(date.getDate()).padStart(2, '0') + getOrdinalSuffix(date.getDate()), // Day of the month (01st-31st)
        "MMS": () => date.toLocaleString('en-US', { month: 'short' }),         // Month abbreviation (Jan-Dec)
        "MML": () => date.toLocaleString('en-US', { month: 'long' }),          // Month abbreviation (January-December)
        "YYYY": () => String(date.getFullYear()),                              // Full year (e.g., 2023)
        "hh": () => String(date.getHours()).padStart(2, '0'),                  // Hours (00-23)
        "HH": () => String(date.getHours() % 12 || 12).padStart(2, '0'),       // Hours (01-12)
        "mm": () => String(date.getMinutes()).padStart(2, '0'),                // Minutes (00-59)
        "AMPM": () => date.getHours() >= 12 ? 'PM' : 'AM',                     // AM/PM
    };

    // Replace format patterns with corresponding values
    let formattedDate = format;
    for (const [pattern, replacer] of Object.entries(patterns)) {
        formattedDate = formattedDate.replace(pattern, replacer());
    }
    return formattedDate;
}

export const isNotNullUndefinedBlank = (data: any) => {
    return (data !== null && data !== undefined && data.trim() !== "");
};

export const isNotNullUndefined = (data: any) => {
    return (data !== null && data !== undefined);
};

export const isValidJSON = (jsonString: string): boolean => {
    try {
        JSON.parse(jsonString);
        return true;
    } catch (error) {
        console.warn(error);
        return false;
    }
};

export const handleError = (error: any) => {
    if (isNotNullUndefined(error) && Object.keys(error).length > 0) {
        Sentry.captureException(error);
        alert(error.message);
    } else {
        console.log("Prematurely onError fired");
    }
}

export const statusWiseOnboardingRedirectToPage = () => {
    const userRoleId = storage.getUserRoleId() ?? '2';
    const userStatus = storage.getUserStatusId();
    const userExchangeStatus = storage.getUserExchangeStatusId();
    const userExchangeAccountId = storage.getUserExchangeAccountId() ?? "";
    const loginProvider = storage.getLoginProvider() ?? "";
    let redirectAt = "/";
    if (userRoleId === '2' && (!isNotNullUndefinedBlank(userExchangeStatus) || storage.isAuthorized())) {
        if (isNotNullUndefinedBlank(userStatus)) {
            if (userStatus === '5' && loginProvider !== "") {
                redirectAt = '/completeprofile';
            }
            else if (userStatus === '5' || userStatus === '4' || userStatus === '2') {
                redirectAt = '/registration/verify';
            } else if (userStatus === '3' || userStatus === '1') {
                if (!isNotNullUndefinedBlank(userExchangeStatus)) {
                    redirectAt = '/connect/binance/info';
                } else {
                    if (userExchangeStatus === '10'|| userExchangeStatus === '9') {
                        if (isNotNullUndefinedBlank(userExchangeAccountId)) {
                            redirectAt = '/account/asset/setup?id=' + userExchangeAccountId;
                        }
                    } else if (userExchangeStatus === '8') {
                        redirectAt = '/connect/binance/consent';
                    } else if (userExchangeStatus === '7') {
                        redirectAt = '/connect/binance/verify';
                    } else if (userExchangeStatus === '6' || userExchangeStatus === '1') {
                        redirectAt = '/dashboard';
                    }
                }
            }
        }
    }
    else if (userRoleId === '1' && Boolean(storage.getIsAdminLoginAsUser())) {
        redirectAt = '/dashboard';
    }
    else if (userRoleId === '1') {
        redirectAt = '/user';
    }
    return redirectAt;
}
export const preventEmptySpaces = (event: any) => {
    if (event.key === ' ') {
        event.preventDefault();
    }
};

export const getTimezoneDisplayName = () => {
    const formatter = new Intl.DateTimeFormat('en-US', { timeZoneName: 'long' });
    const parts = formatter.formatToParts(new Date());
    for (const part of parts) {
        if (part.type === 'timeZoneName') {
            return part.value.toString();
        }
    }
    return "UTC";
};

export const handleEmptySpaces = (event: any) => {
    const inputValue = event.target.value;
    event.target.value = inputValue.replace(/\s+/g, ' ');
};

export const isTokenValid = (data: any) => {
    const tokenErrors = ["Access Token Has Expired", "Refresh Token Has Expired"];
    const isTokenError = tokenErrors.some((msg: any) => data.statusMessage.toLowerCase().includes(msg.toLowerCase()));
    const isTokenExists = isNotNullUndefinedBlank(storage.getToken()) && isNotNullUndefinedBlank(storage.getRefreshToken());
    if ((data.statusCode === 401 || data.statusCode === 500) && isTokenExists && isTokenError) {
        return false;
    } else {
        return true;
    }
};

export const filterUniqueData = (data: any, propertyName: any) => {
    const uniqueValues = new Set();
    return data.filter((item: any) => {
        const propertyValue = item[propertyName];
        if (uniqueValues.has(propertyValue)) {
            return false;
        } else {
            uniqueValues.add(propertyValue);
            return true;
        }
    });
}

const useRenewAccessToken = (): (() => Promise<any>) => {
    const navigate = useNavigate();
    const getAccessTokenMutation: UseMutationResult<any, any, any, any> = useGetAccessToken({
        onSuccess: (response) => {
            if (isTokenValid(response) && isNotNullUndefined(response.data) && response.statusCode === 200) {
                storage.setToken(response.data.token);
            } else if (isNotNullUndefinedBlank(storage.getToken()) && isNotNullUndefinedBlank(storage.getRefreshToken())) {
                window.localStorage.clear();
                navigate("/");
            }
            else {
                console.error(response);
            }
        },
        onError: (error) => {
            console.error(error);
        }
    });

    return async (): Promise<any> => {
        try {
            return await getAccessTokenMutation.mutateAsync({});
        } catch (error) {
            console.error(error);
        }
    };
};

export const formatNumberSystem = (value: any, system: string) => {
    // Convert the number to a string
    let numStr = value.toString() || '0';
    if (!isNaN(parseFloat(numStr))) {
        if (system === "INS") {
            // For International Numbering System
            let chars = numStr.split('');
            let result = chars.reduce((accumulator: any, char: string, index: number) => {
                // Insert comma every three digits, but not at the beginning
                if (index > 0 && (chars.length - index) % 3 === 0) {
                    accumulator += ',';
                }
                accumulator += char;
                return accumulator;
            }, '');

            return result;
        }
        else if (system === "SANS") {
            // For South Asian Numbering System
            let lastThree = numStr.slice(-3); // Take the last three digits
            let otherNumbers = numStr.slice(0, -3); // Take the digits before the last three
            if (otherNumbers !== "") {
                let chars = otherNumbers.split('');
                let result = chars.reduce((accumulator: any, char: string, index: number) => {
                    // Insert comma every two digits, but not at the beginning
                    if (index > 0 && (chars.length - index) % 2 === 0) {
                        accumulator += ',';
                    }
                    accumulator += char;
                    return accumulator;
                }, '');

                return result + "," + lastThree; // Combine with the last three digits
            } else {
                return lastThree;
            }
        }
        else if (system === "NONE") {
            // For extracting normal digits from comma-separated numbers.
            return parseFloat(value.toString().replace(/,/g, ""));
        }
        else {
            console.error("Invalid number system type.");
        }
    } else {
        console.error("Invalid value passed.");
    }
}

export const isValidDate = (date: any): boolean => {
    return date instanceof Date && !isNaN(date.getTime());
};

export default useRenewAccessToken;
